import { Controller } from '@hotwired/stimulus'
import ModalController from './modal_controller'

export default class extends Controller<HTMLFormElement> {
  static targets = ['button']
  static outlets = ['modal']

  declare readonly buttonTarget: HTMLButtonElement
  declare readonly modalOutlet: ModalController

  connect() {
    this.buttonTarget.onclick = this.onClick.bind(this)
  }

  onClick() {
    this.modalOutlet.open(
      () => {},
      () => {}
    )
  }
}
