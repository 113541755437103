// Adpapted from https://unpkg.com/@massds/mayflower-assets@12.6.0/js/brand-banner.js which came from the Mayflower component library: https://mayflower.digital.mass.gov/core/index.html?path=/docs/components-brandbanner--brand-banner-example#dark-theme

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['button', 'toggle', 'expandable']

  declare readonly buttonTarget: HTMLButtonElement
  declare readonly toggleTarget: HTMLDivElement
  declare readonly expandableTarget: HTMLDivElement

  connect() {
    if (this.toggleTarget) {
      this.buttonTarget.setAttribute(
        'aria-controls',
        'ma__brand-banner-content'
      )
      this.buttonTarget.setAttribute('aria-expanded', 'false')
    }
  }

  onClick() {
    this.toggleTarget.classList.toggle('ma__button-icon--expanded')
    this.expandableTarget.classList.toggle(
      'ma__brand-banner-expansion--expanded'
    )

    const isExpanded = this.buttonTarget.getAttribute('aria-expanded') == 'true'
    this.buttonTarget.setAttribute('aria-expanded', `${!isExpanded}`)
  }

  onMouseover() {
    this.toggleTarget.classList.add('active')
  }

  onMouseout() {
    this.toggleTarget.classList.remove('active')
  }
}
