import { Turbo } from '@hotwired/turbo-rails'
import { Controller } from '@hotwired/stimulus'

export default class extends Controller<HTMLTableRowElement> {
  static values = {
    url: String,
  }

  declare readonly urlValue: string

  public navigate(e: Event) {
    Turbo.visit(this.urlValue)
  }
}
