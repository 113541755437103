import { Controller } from '@hotwired/stimulus'

export default class extends Controller<HTMLDivElement> {
  static targets = ['cancel', 'confirm']

  declare readonly cancelTarget: HTMLButtonElement
  declare readonly confirmTarget: HTMLButtonElement

  private onConfirm: () => void
  private onCancel: () => void

  async open(onConfirm: () => void, onCancel: () => void) {
    this.onConfirm = onConfirm
    this.onCancel = onCancel
    this.showModal()
  }

  confirm(e: Event) {
    e.stopPropagation()
    e.preventDefault()
    this.onConfirm()
    this.hideModal()
  }

  cancel(e: MouseEvent | KeyboardEvent) {
    if (
      e instanceof KeyboardEvent ||
      e.target == this.cancelTarget ||
      e.target == this.element
    ) {
      e.stopPropagation()
      e.preventDefault()

      this.onCancel()
      this.hideModal()
    }
  }

  private showModal() {
    this.element.classList.add('modal-container-open')
  }

  private hideModal() {
    this.element.classList.remove('modal-container-open')
  }
}
