import { Controller } from '@hotwired/stimulus'
import ModalController from './modal_controller'

export default class extends Controller<HTMLFormElement> {
  static targets = ['submit']
  static outlets = ['modal']

  declare readonly submitTarget: HTMLButtonElement
  declare readonly modalOutlet: ModalController

  connect() {
    this.submitTarget.disabled = true
    this.element.onclick = this.onClick.bind(this)
    this.element.onchange = this.formValuesChanged.bind(this)
    this.element.onsubmit = this.onSubmit.bind(this)
    if (this.element.checkValidity()) {
      this.submitTarget.disabled = false
    }
  }

  formValuesChanged(e: Event) {
    if (this.element.checkValidity() && this.submitTarget.disabled) {
      this.submitTarget.disabled = false
    } else if (!this.element.checkValidity() && !this.submitTarget.disabled) {
      this.submitTarget.disabled = true
    }
  }

  async onSubmit(e: Event) {
    if (!this.confirmed) {
      e.stopPropagation()
      e.preventDefault()

      this.submitTarget.disabled = true
      this.modalOutlet.open(
        this.confirmCallback.bind(this),
        this.cancelCallback.bind(this)
      )
    }
  }

  onClick(e: Event) {
    e.stopPropagation()
  }

  private confirmed = false

  private cancelCallback() {
    this.confirmed = false
    this.submitTarget.disabled = false
  }

  private confirmCallback() {
    this.confirmed = true
    this.element.submit()
  }
}
